import React from "react"
import classNames from "classnames"
import { DateTime } from "luxon"
import { useIntl } from "gatsby-plugin-intl"

import componentStyles from "./BlogPostCard.module.scss"
import { Link } from "../Link"

interface BlogPostCardProps {
  className?: string
  author?: any
  date: string
  externalLink?: string
  slug: string
  title: string
  thumbnail?: {
    childImageSharp: {
      fixed: {
        src: string
        srcSet: string
        srcSetWebp: string
        width: number
        height: number
      }
    }
  }
}

const BlogPostCard: React.FC<BlogPostCardProps> = ({
  className,
  date,
  title,
  externalLink,
  thumbnail,
  slug,
}) => {
  const intl = useIntl()
  const dir = "ltr"
  const locale = intl.locale === "ar" ? "en" : intl.locale
  const dateObject = DateTime.fromISO(date).setLocale(locale)
  const formattedTitleDate = dateObject.toLocaleString(DateTime.DATE_FULL)
  const formattedDisplayingDate = dateObject.toLocaleString(DateTime.DATE_SHORT)

  const cardClasses = classNames(componentStyles.blogPostCard, className)

  const isExternal = !!externalLink
  const link = isExternal ? externalLink : slug
  const ExternalBadge = isExternal ? (
    <div className={componentStyles.blogPostCardExternalBadge}>
      <span className={componentStyles.blogPostCardExternalBadgeText}>
        {intl.formatMessage({ id: "index.blog.external-badge" })}
      </span>
    </div>
  ) : null

  const Thumbnail = !thumbnail ? (
    <picture className={componentStyles.blogPostCardThumbnail} />
  ) : (
    <picture className={componentStyles.blogPostCardThumbnail}>
      <source
        type="image/webp"
        srcSet={thumbnail.childImageSharp.fixed.srcSetWebp}
      />
      <source
        type="image/png"
        srcSet={thumbnail.childImageSharp.fixed.srcSet}
      />
      <img
        src={thumbnail.childImageSharp.fixed.src}
        srcSet={thumbnail.childImageSharp.fixed.srcSet}
        width={thumbnail.childImageSharp.fixed.width}
        height={thumbnail.childImageSharp.fixed.height}
        alt={`Thumbnail of '${title}' post`}
      />
    </picture>
  )

  return (
    <article className={cardClasses} dir={dir}>
      <Link
        className={componentStyles.blogPostCardLink}
        to={link}
        isIntl={false}
      >
        {Thumbnail}
        <div className={componentStyles.blogPostCardDescription}>
          <time
            className={componentStyles.blogPostCardDate}
            dateTime={date}
            title={formattedTitleDate}
          >
            {formattedDisplayingDate}
          </time>
          <h4 className={componentStyles.blogPostCardTitle} title={title}>
            {title}
          </h4>
          {ExternalBadge}
        </div>
      </Link>
    </article>
  )
}

export default BlogPostCard
