import React from "react"
import classNames from "classnames"

import "../../assets/styles/common.scss"
import componentStyles from "./Layout.module.scss"
import Header from "../Header/Header"
import { useIntl } from "gatsby-plugin-intl"
import Footer from "../Footer/Footer"

export interface LayoutProps {
  location: {
    pathname: string
  }
  children: any
  className?: string
}

const Layout: React.FC<LayoutProps> = ({ children, className, location }) => {
  const { pathname } = location
  const intl = useIntl()
  const isHome = pathname === `/${intl.locale}/`

  const layoutClasses = classNames(componentStyles.layout, className)

  return (
    <div className={layoutClasses}>
      <Header isHome={isHome} location={location} />
      <main>{children}</main>
      <Footer pathname={pathname} />
    </div>
  )
}

export default Layout
