interface ProjectItem {
  title: string
  link?: string
  thumbnail?: {
    childImageSharp: {
      fixed: {
        src: string
        srcSet: string
        srcSetWebp: string
        width: number
        height: number
      }
    }
  }
  theme?: "light" | "blue" | "black"
  color?: string
  banner?: string
  textColor?: string
  backgroundSize?: string
  rightBlockStyle?: string
  buttonColor?: "orange" | "blue" | "white" | "black"
  leftBlockStyle?: string
}

const projectsList: ProjectItem[] = [
  {
    title: "projects.maxima-telecom.banner.title",
    link: "maxima-telecom",
    theme: "light",
    color: "#f9f9f9",
    banner: "url('/images/maxima-telecom.png')",
    textColor: "#050D71",
  },
  {
    title: "projects.brand-affinity.banner.title",
    link: "brand-affinity",
    theme: "light",
    color: "#f9f9f9",
    banner: "url('/images/brand-affinity-preview.png)",
    textColor: "#050D71",
  },
  {
    title: "projects.b2-connect.banner.title",
    link: "b2-connect",
    theme: "light",
    color: "#f9f9f9",
    banner: "url('/images/b2-connect.png')",
    textColor: "#050D71",
  },
  {
    title: "projects.nexus-mentis.banner.title",
    link: "nexus-mentis",
    theme: "light",
    color: "#f9f9f9",
    banner: "url('/images/nexus-mentis.png')",
    textColor: "#050D71",
  },
  {
    title: "projects.convidi.banner.title",
    link: "convidi",
    theme: "light",
    color: "#f9f9f9",
    banner: "url('/images/convidi.png)",
    textColor: "#050D71",
  },
  {
    title: "projects.loyalty-manager.banner.title",
    link: "loyalty-manager",
    theme: "light",
    color: "#f9f9f9",
    banner: "url('/images/movie-teller.png')",
    textColor: "#050D71",
  },
  {
    title: "projects.backlite-media.banner.title",
    link: "backlite-media",
    theme: "light",
    color: "#2cd5c4",
  },
  {
    title: "projects.your-stylist.banner.title",
    link: "your-stylist",
    theme: "blue",
    color: "#eef8f9",
    textColor: "#006b89",
  },
  {
    title: "projects.video-sift.banner.title",
    link: "video-sift",
    theme: "black",
    color: "linear-gradient(to left, #fbf8f82e 52%, #fffefe8c 74%)",
    banner: "url('/images/video-sift.png')",
    textColor: "#212121",
  },
  {
    title: "projects.mosaic.banner.title",
    link: "mosaic",
    theme: "light",
    color: "linear-gradient(180deg, #f76425 0%, #ff2e00 100%)",
  },
  {
    title: "projects.movie-teller.banner.title",
    link: "movie-teller",
    theme: "light",
    color: "#212121",
    banner: "url('/images/movie-teller.png')",
  },
  {
    title: "projects.sportex.banner.title",
    link: "sportex",
    theme: "black",
    color: "#f9f9f9",
    banner: "url('/images/sportex.png')",
    textColor: "#050D71",
  },
]

export function findIndex(url) {
  let indexProject = 0
  projectsList.forEach(({ link }, i) => {
    if (link === url) {
      indexProject = i
    }
  })
  return indexProject
}

export { projectsList, ProjectItem }
