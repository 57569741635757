import React from "react"
import componentStyles from "./Button.module.scss"
import { Link } from "../Link"
import classNames from "classnames"
import { capitalize } from "lodash"

interface ButtonType {
  to?: string
  children: any
  color?: "orange" | "blue" | "white" | "black"
  filled?: boolean
  className?: string
  disabled?: boolean
  [x: string]: any
}

const Button: React.FC<ButtonType> = ({
  to,
  filled = false,
  className,
  children,
  disabled = false,
  color,
  ...other
}) => {
  const Element = to ? Link : "button"
  const elementClasses = classNames(componentStyles.button, className, {
    [componentStyles.buttonFilled]: filled,
    [componentStyles[`buttonColor${capitalize(color)}`]]: color,
  })

  return (
    <Element className={elementClasses} disabled={disabled} to={to} {...other}>
      {children}
    </Element>
  )
}

export default Button
