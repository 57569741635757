import { useStaticQuery, graphql } from 'gatsby'

const useAuthorsRu = () => {
  const { authors } = useStaticQuery(
    graphql`
      query AuthorsRu {
        authors: allMarkdownRemark(
          filter: {
            fields: { language: { eq: "ru" }, type: { eq: "authors" } }
          }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              fields {
                language
              }
              frontmatter {
                id
                name
                position
                avatar {
                  childImageSharp {
                    fixed(width: 280) {
                      src
                      srcSet
                      srcSetWebp
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return authors
}

export { useAuthorsRu }
