import { graphql, useStaticQuery } from "gatsby"

export const useLastEnPosts = () => {
  const { posts } = useStaticQuery(
    graphql`
      query LastEnPosts {
        posts: allMarkdownRemark(
          filter: {
            frontmatter: { draft: { ne: true } }
            fields: { language: { eq: "en" }, type: { eq: "blog" } }
          }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                date
                title
                externalLink
                thumbnail {
                  childImageSharp {
                    fixed(width: 550) {
                      src
                      srcSet
                      srcSetWebp
                      width
                      height
                    }
                  }
                }
                type
              }
            }
          }
        }
      }
    `
  )
  return posts
}
