import React from "react"
import { Link as IntlLink } from "gatsby-plugin-intl"
import { Link as GatsbyLink } from "gatsby"

interface LinkProps {
  children: any
  to: string
  isIntl?: boolean
  className?: string
  [x: string]: any
}

export const Link: React.FC<LinkProps> = ({
  children,
  to,
  isIntl = true,
  className,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  const anchor = /^#/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal || anchor) {
    const LinkComponent = isIntl ? IntlLink : GatsbyLink
    return (
      <LinkComponent className={className} to={to} {...other}>
        {children}
      </LinkComponent>
    )
  }
  return (
    <a
      className={className}
      href={to}
      {...other}
      rel="noopener"
      target="_blank"
    >
      {children}
    </a>
  )
}
