import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import { DateTime } from "luxon"
import { isNumber } from "lodash"
import classNames from "classnames"

import Layout from "../../components/Layout/Layout"
import SEO, { PageTypes } from "../../components/seo"
import componentStyles from "./blog-post.module.scss"
import BlogPostCard from "../../components/BlogCard/BlogPostCard"
import { useBlogPosts } from "../../hooks"
import { useAuthorsEn } from '../../hooks/useAuthorsEn'
import { useAuthorsRu } from '../../hooks/useAuthorsRu'
import { Locale } from "../../types/Locale.enum"

export const MutedLocalesContext = React.createContext([])

const BlogPostTemplate = ({ data, location }) => {
  const intl = useIntl()
  const post = data.markdownRemark
  const {
    author,
    date,
    introduction,
    title,
    thumbnail,
    description,
  } = post.frontmatter
  const minutesToRead = Math.round(
    (post &&
      post.fields &&
      post.fields.readingTime &&
      post.fields.readingTime.minutes) ||
      1
  )
  const authorsByLanguage = {
    en: useAuthorsEn(),
    ru: useAuthorsRu(),
  }
  const dateObject = DateTime.fromISO(date).setLocale(intl.locale)
  const formattedTitleDate = dateObject.toLocaleString(DateTime.DATE_FULL)
  const formattedDisplayingDate = dateObject.toLocaleString(DateTime.DATE_FULL)
  const authorArray =
    authorsByLanguage[intl.locale] &&
    authorsByLanguage[intl.locale].edges &&
    authorsByLanguage[intl.locale].edges.filter(
      ({ node }) => author.includes(node.frontmatter.id)
    )
  const authorInfo = authorArray && authorArray.map(({ node }) => ({
    name: node.frontmatter.name,
    position: node.frontmatter.position,
    avatar: node.frontmatter.avatar.childImageSharp.fixed.src,
  }))

  const blogPosts = useBlogPosts(intl.locale)

  const blogPostsDisplaying = {
    edges: [],
  }
  const postsLimit = 3
  const currentPostIndex =
    blogPosts &&
    blogPosts.edges &&
    blogPosts.edges.findIndex(
      ({ node }) =>
        location &&
        location.pathname &&
        node &&
        node.fields &&
        location.pathname.includes(node.fields.slug)
    )
  if (
    isNumber(currentPostIndex) &&
    currentPostIndex !== -1 &&
    currentPostIndex <= postsLimit
  ) {
    blogPostsDisplaying.edges = blogPosts.edges.filter(
      (post, index) => index !== currentPostIndex
    )
  }
  useEffect(() => {
    blogPosts.edges.forEach(edges => {
      if (
        edges.node.frontmatter.externalLink &&
        edges.node.fields.slug === window.location.pathname
      ) {
        window.location.replace(edges.node.frontmatter.externalLink)
      }
    })
    for (const edges of blogPosts.edges) {
      if (
        edges.node.frontmatter.externalLink &&
        edges.node.fields.slug === window.location.pathname
      ) {
        window.location.replace(edges.node.frontmatter.externalLink)
        break
      }
    }
  })

  blogPostsDisplaying.edges = blogPosts.edges.filter(
    (post, index) => index < postsLimit
  )

  const Author = authorInfo ? (
    <section className={componentStyles.authorBlock}>
      {authorInfo.map(author => (
        <figure className={componentStyles.author} key={author.name}>
          <img
            className={componentStyles.authorPhoto}
            src={author.avatar}
            alt={author.name}
            width="48"
            height="48"
          />
          <figcaption className={componentStyles.authorInfo}>
            <span className={componentStyles.authorName}>{author.name}</span>
            <span className={componentStyles.authorPosition}>
              {author.position}
            </span>
          </figcaption>
        </figure>
      ))}
    </section>
  ) : null
  const Introduction = !introduction ? null : (
    <p className={componentStyles.introduction}>{introduction}</p>
  )

  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container"
  )
  const titleClasses = classNames(componentStyles.title, "large-section-title")
  const contentClasses = classNames(componentStyles.content, "container")

  const blogWrapperClasses = classNames(componentStyles.blogWrapper)
  const blogItemClasses = classNames(componentStyles.blogItem)
  const blogListClasses = classNames(componentStyles.blogList, "grid-3")
  const blogPostSectionClasses = classNames(
    componentStyles.blogPostSection,
    "container"
  )
  const schemaOrgData = {
    date,
    author:
      authorInfo &&
      authorInfo.map(author => ({
        name: author.name,
        jobTitle: author.position,
      })),
  }

  return (
    <MutedLocalesContext.Provider value={[Locale.AR]}>
      <Layout location={location}>
        <SEO
          language={intl.locale}
          title={title}
          description={description || post.excerpt}
          pathname={location.pathname}
          pageType={PageTypes.BLOG_POST}
          schemaOrgData={schemaOrgData}
        />
        <article>
          <header
            className={componentStyles.banner}
            style={{
              backgroundImage:
                thumbnail &&
                thumbnail.childImageSharp &&
                thumbnail.childImageSharp.fixed &&
                thumbnail.childImageSharp.fixed.src
                  ? `url(${thumbnail.childImageSharp.fixed.src})`
                  : null,
            }}
          >
            <div className={bannerContainerClasses}>
              {post.description}
              <h1 className={titleClasses}>{post.frontmatter.title}</h1>
              <div className={componentStyles.postInfo}>
                <time
                  className={componentStyles.date}
                  dateTime={date}
                  title={formattedTitleDate}
                >
                  {formattedDisplayingDate}
                </time>
                ,{" "}
                <span className={componentStyles.timeToRead}>
                  {minutesToRead} {intl.formatMessage({ id: "blog.post-reading-time" })}
                </span>
              </div>
            </div>
          </header>
          <section className={contentClasses}>
            {Introduction}
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            {Author}
          </section>
          <section className={blogPostSectionClasses}>
            <div className={blogWrapperClasses}>
              <ul className={blogListClasses}>
                {blogPostsDisplaying.edges.map(({ node }) => {
                  const {
                    date,
                    title,
                    externalLink,
                    thumbnail,
                  } = node.frontmatter
                  const { slug } = node.fields
                  return (
                    <li key={slug} className={blogItemClasses}>
                      <BlogPostCard
                        date={date}
                        title={title}
                        externalLink={externalLink}
                        slug={slug}
                        thumbnail={thumbnail}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
        </article>
      </Layout>
    </MutedLocalesContext.Provider>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        description
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        readingTime {
          minutes
        }
      }
      frontmatter {
        author
        description
        title
        date
        introduction
        thumbnail {
          childImageSharp {
            fixed(width: 1920) {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`
