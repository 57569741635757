import React, { MouseEventHandler, useEffect, useState } from "react"
import componentStyles from "./Header.module.scss"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "../Link"
import classNames from "classnames"
import Logo from "../../assets/images/logo.svg"
import BurgerMenu from "../../assets/images/burger.svg"
import BurgerMenuClosed from "../../assets/images/burger-closed.svg"
import Button from "../Button/Button"
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher"
import { useMixpanel } from "gatsby-plugin-mixpanel"

interface HeaderProps {
  isHome: boolean
  location: any
}

const menuItems = [
  {
    name: "services",
    path: "/services/",
    translatePath: "header.navigation.services",
  },
  {
    name: "expertise",
    path: "/expertise/",
    translatePath: "header.navigation.expertise",
  },
  {
    name: "projects",
    path: "/projects/",
    translatePath: "header.navigation.projects",
  },
  {
    name: "blog",
    path: "/blog/",
    translatePath: "header.navigation.blog",
  },
  {
    name: "about",
    path: "/about/",
    translatePath: "header.navigation.about",
  },
]

const servicesItems = [
  {
    name: "development",
    path: "/services/development",
    translatePath: "footer.navigation.development",
  },
  {
    name: "consulting",
    path: "/services/consulting",
    translatePath: "footer.navigation.consulting",
  },
  {
    name: "data-science",
    path: "/services/data-science",
    translatePath: "footer.navigation.data-science",
  },
  {
    name: "cloud",
    path: "/services/cloud",
    translatePath: "footer.navigation.cloud",
  },
]

const Header: React.FC<HeaderProps> = ({ isHome, location }) => {
  const intl = useIntl()
  const mixpanel = useMixpanel()
  const [currentPath, currentPathSetter] = useState<string>("")
  const { pathname } = location
  const [burgerActive, burgerActivitySetter] = useState(false)
  let scrollPrev = 0
  let scrolled = 0
  let animationTick = false
  const [isHeaderSticky, isHeaderStickySetter] = useState(false)

  const burgerButtonClasses = classNames(componentStyles.burgerButton, {
    [componentStyles.burgerButtonActive]: burgerActive,
  })
  const logoClasses = classNames(componentStyles.logo)
  const headerClasses = classNames(componentStyles.header, {
    [componentStyles.headerSticky]: isHeaderSticky,
  })
  const headerContainerClasses = classNames(
    componentStyles.headerContainer,
    "container",
    "grid"
  )

  // Burger Menu Logic
  useEffect(() => {
    if (
      document &&
      document.body &&
      document.body.classList.contains("with-burger-active")
    ) {
      document.body.classList.remove("with-burger-active")
    }
  }, [])

  useEffect(() => {
    const path = pathname.split("/")[2]
    if (path) {
      currentPathSetter(`/${path}/`)
    }
  }, [pathname])

  const onBurgerToggle = () => {
    if (!burgerActive) {
      document.body.classList.add("with-burger-active")
    } else {
      document.body.classList.remove("with-burger-active")
    }
    burgerActivitySetter(!burgerActive)
  }
  const burgerSrc = burgerActive ? BurgerMenuClosed : BurgerMenu

  // Sticky Header Logic
  const update = () => {
    animationTick = false
    scrolled = window.scrollY
    isHeaderStickySetter(scrolled < scrollPrev)
  }

  const onContactUs = () => {
    if (burgerActive) {
      onBurgerToggle();
    }
    mixpanel.track('Click on "Contact us" button')
  }

  const onHeaderScroll = () => {
    scrollPrev = scrolled
    requestTick()
  }

  const requestTick = () => {
    if (!animationTick) {
      requestAnimationFrame(update)
    }
    animationTick = true
  }

  useEffect(() => {
    window.addEventListener("scroll", onHeaderScroll)
    return () => {
      window.removeEventListener("scroll", onHeaderScroll)
    }
  }, [pathname])

  return (
    <header className={headerClasses}>
      <div className={headerContainerClasses}>
        <Link to="/" className={logoClasses}>
          <img
            className={componentStyles.logoImage}
            src={Logo}
            alt="Inventale logo"
            width="110"
            height="26"
          />
        </Link>
        <button
          type="button"
          className={burgerButtonClasses}
          onClick={onBurgerToggle}
        >
          <img src={burgerSrc} alt="Menu icon" width="24" height="24" />
        </button>
        <nav className={componentStyles.navigation}>
          <ul className={componentStyles.navigationList}>
            {menuItems.map((item, index) => {
              const hasDropdown = item.name === "services"
              const itemClasses = classNames(componentStyles.navigationItem, {
                [componentStyles.navigationItemWithDropdown]: hasDropdown,
              })
              return (
                <li key={index} className={itemClasses}>
                  <Link
                    to={
                      item.name === "blog"
                        ? item.path.replace("{locale}", intl.locale)
                        : item.path
                    }
                    className={classNames(componentStyles.navigationLink, {
                      [componentStyles.activeLink]: item.path === currentPath,
                    })}
                  >
                    {intl.formatMessage({ id: item.translatePath })}
                  </Link>

                  {!hasDropdown ? null : (
                    <ul className={componentStyles.dropdownMenu}>
                      {servicesItems.map((item, index) => (
                        <li
                          key={index}
                          className={componentStyles.dropdownMenuItem}
                        >
                          <Link
                            to={item.path}
                            className={classNames(
                              componentStyles.dropdownMenuLink,
                              {
                                [componentStyles.activeLink]: pathname.includes(
                                  item.path
                                ),
                              }
                            )}
                          >
                            {intl.formatMessage({ id: item.translatePath })}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
          <LanguageSwitcher
            location={location}
            className={componentStyles.switcher}
          />
          <Button
            className={componentStyles.contactButton}
            color={"orange"}
            to="#contact-form"
            isIntl={false}
            onClick={onContactUs}
          >
            {intl.formatMessage({ id: "header.contact" })}
          </Button>
        </nav>
      </div>
    </header>
  )
}

export default Header
