/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { languages } from "./LanguageSwitcher/LanguageSwitcher"
import { useIntl } from "gatsby-plugin-intl"

export interface SEOProps {
  children?: any
  description: string
  title: string
  pathname: string
  language: string
  direction?: "rtl" | "ltr"
  pageType?: PageTypes
  schemaOrgData?: any
}

export enum PageTypes {
  BLOG_POST = "blog-post",
  DEFAULT = "default",
  PROJECT = "project",
  SERVICE = "service",
}

interface SiteMetaProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      siteUrl: string
    }
  }
  pathname: string
}

const getSchemaOrgJSONLD = (pageType, data, organizationData) => {
  const { description, title, url } = data
  switch (pageType) {
    case PageTypes.BLOG_POST:
      const { date, author } = data
      return {
        "@context": "http://schema.org",
        "@type": "Article",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": url,
        },
        headline: title,
        image: {
          "@type": "ImageObject",
          url: "/images/site-image.png",
          width: 1200,
          height: 630,
        },
        datePublished: date,
        author:
          author &&
          author.map(author => ({
            "@type": "Person",
            name: author.name,
            jobTitle: author.jobTitle,
          })),
        publisher: organizationData,
      }
    default:
      return {
        "@context": "http://schema.org",
        ...organizationData,
      }
  }
}

const SEO = ({
  children,
  description,
  title,
  pathname,
  language,
  direction,
  pageType = PageTypes.DEFAULT,
  schemaOrgData,
}: SEOProps) => {
  const intl = useIntl()
  const dir = intl?.locale === "ar" ? "rtl" : "ltr"
  const { site } = useStaticQuery<SiteMetaProps>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )
  const schemaOrgJSONLDOrganization = {
    "@type": "Organization",
    name: "Inventale",
    logo: {
      "@type": "ImageObject",
      name: `Inventale simplified logo`,
      url: `${site.siteMetadata.siteUrl}/images/inventale-square.png`,
      width: 60,
      height: 60,
    },
    url: site.siteMetadata.siteUrl,
    email: intl.formatMessage({ id: "contact-form.mail" }),
    telephone: intl.formatMessage({ id: "contact-form.phone" }),
  }

  const metaDescription = description || site.siteMetadata.description
  const url = `${site.siteMetadata.siteUrl}${pathname}`
  const alternateLanguagesLinks =
    languages &&
    languages.map(currentLanguage => {
      const href =
        pathname && language
          ? pathname.replace(language, currentLanguage)
          : pathname || ""
      return {
        rel: "alternate",
        hrefLang: currentLanguage,
        href: `${site.siteMetadata.siteUrl}${href}`,
      }
    })
  const schemaOrgJSONLD = getSchemaOrgJSONLD(
    pageType,
    {
      description: metaDescription,
      title,
      url,
      ...schemaOrgData,
    },
    schemaOrgJSONLDOrganization
  )

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:image`,
          content: `/images/site-image.png`,
        },
        {
          property: `og:image:width`,
          content: `1200`,
        },
        {
          property: `og:image:height`,
          content: `630`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: url,
        },
        {
          name: `twitter:image`,
          content: `/images/site-image.png`,
        },
      ]}
      link={[
        {
          rel: "canonical",
          href: url,
        },
        ...alternateLanguagesLinks,
      ]}
    >
      <html lang={language} dir={dir} />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      {children}
    </Helmet>
  )
}

export default SEO
