import React, { useContext, useState } from "react"
import componentStyles from "./LanguageSwitcher.module.scss"
import classNames from "classnames"
import { useIntl } from "gatsby-plugin-intl"
import { Locale } from "../../types/Locale.enum"
import { MutedLocalesContext } from "../../templates/blog-post/blog-post"

interface LanguageSwitcher {
  children?: any
  className?: string
  location: any
  [x: string]: any
}

export const languages = [Locale.EN, Locale.AR]

const LanguageSwitcher: React.FC<LanguageSwitcher> = ({
  className,
  children,
  location,
  ...other
}) => {
  const intl = useIntl()
  const { locale } = intl
  const elementClasses = classNames(componentStyles.switcher, className)
  const [listVisible, listVisibilitySetter] = useState(false)
  const switcherButtonClasses = classNames(componentStyles.switcherButton)
  const href = (location && location.pathname) || `/`
  const mutedLocales = useContext(MutedLocalesContext)
  const filteredLocales =
    !mutedLocales || mutedLocales.length === 0
      ? languages
      : languages.filter(locale => !mutedLocales.includes(locale))

  const toggleList = () => {
    listVisibilitySetter(!listVisible)
  }

  const Union = (
    <svg
      className={componentStyles.union}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM11 17.6973C11.4874 17.4096 12.0177 16.8921 12.5277 16.0421C12.7173 15.7259 12.8943 15.3774 13.0551 15H11V17.6973ZM9 17.6973V15H6.94489C7.10569 15.3774 7.28267 15.7259 7.47235 16.0421C7.98233 16.8921 8.51261 17.4096 9 17.6973ZM11 13H13.6846C13.8219 12.373 13.9188 11.7033 13.9664 11H11V13ZM9 11V13H6.31544C6.17813 12.373 6.08124 11.7033 6.03361 11H9ZM11 9H13.9664C13.9188 8.29673 13.8219 7.62697 13.6846 7H11V9ZM9 7V9H6.03361C6.08124 8.29673 6.17813 7.62697 6.31544 7H9ZM11 5H13.0551C12.8943 4.62263 12.7173 4.27405 12.5277 3.95792C12.0177 3.10794 11.4874 2.59041 11 2.30272V5ZM9 2.30272C8.51261 2.59041 7.98233 3.10794 7.47235 3.95792C7.28267 4.27405 7.10569 4.62263 6.94489 5H9V2.30272ZM15.7253 13C15.8462 12.3588 15.9293 11.6897 15.9704 11H17.9381C17.851 11.6986 17.6738 12.3693 17.4185 13H15.7253ZM17.4185 7C17.6738 7.6307 17.851 8.30136 17.9381 9H15.9704C15.9293 8.31031 15.8462 7.64124 15.7253 7H17.4185ZM2.06189 9H4.02962C4.07071 8.31031 4.15384 7.64124 4.27468 7H2.58152C2.32622 7.6307 2.14901 8.30136 2.06189 9ZM2.58152 13C2.32622 12.3693 2.14901 11.6986 2.06189 11H4.02963C4.07071 11.6897 4.15384 12.3588 4.27468 13H2.58152ZM15.1973 15C14.9968 15.5776 14.7627 16.1187 14.4994 16.6158C15.1587 16.1665 15.7474 15.6212 16.2454 15H15.1973ZM3.75463 15H4.8027C5.00317 15.5776 5.2373 16.1187 5.50063 16.6158C4.84128 16.1665 4.25258 15.6212 3.75463 15ZM15.1973 5H16.2454C15.7474 4.37883 15.1587 3.83354 14.4994 3.38425C14.7627 3.88135 14.9968 4.42241 15.1973 5ZM5.50063 3.38425C5.2373 3.88135 5.00316 4.42241 4.80269 5H3.75463C4.25258 4.37883 4.84128 3.83354 5.50063 3.38425Z"
        fill="#A3A3A3"
      />
    </svg>
  )

  return (
    <ul className={elementClasses} {...other}>
      <li className={componentStyles.switcherItem}>
        <a onClick={toggleList} className={switcherButtonClasses} tabIndex={0}>
          {Union}
          {intl.formatMessage({ id: `header.switcher.${locale}` })}
        </a>
        <ul className={componentStyles.list}>
          {filteredLocales.map((language, index) => (
            <li
              className={classNames(componentStyles.listItem, {
                [componentStyles.listItemCurrent]: language === locale,
              })}
              key={index}
            >
              <a
                href={
                  language === locale ? href : href.replace(locale, language)
                }
                tabIndex={0}
              >
                {Union}
                <span className={componentStyles.listItemValue}>
                  {intl.formatMessage({ id: `header.switcher.${language}` })}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  )
}

export default LanguageSwitcher
