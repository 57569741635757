import { useLastEnPosts } from "../hooks"

export const useBlogPosts = (locale: string = "en") => {
  switch (locale) {
    case "en":
    case "ar":
      return useLastEnPosts()
    default:
      return {
        edges: [],
      }
  }
}
