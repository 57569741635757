import { useStaticQuery, graphql } from "gatsby"

export const usePartners = () => {
  const { partners } = useStaticQuery(
    graphql`
      query Partners {
        partners: allFile(
          filter: { relativeDirectory: { eq: "partners" } }
          sort: { fields: relativePath }
        ) {
          edges {
            node {
              childImageSharp {
                fixed(width: 280) {
                  src
                  srcSet
                  srcSetWebp
                  width
                  height
                }
              }
            }
          }
        }
      }
    `
  )
  return partners
}
