import React, { useLayoutEffect, useRef, useState } from "react"
import classNames from "classnames"
import { useIntl } from "gatsby-plugin-intl"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { useFormik } from "formik"
import * as yup from "yup"
import { debounce, uniqueId } from "lodash"

import componentStyles from "./ContactForm.module.scss"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
import Button from "../Button/Button"
import { Link } from "../Link"

const phoneRegExp = /^[0-9+ (]+[0-9 \-()]{1,15}$/
const notCorporateEmailDomains = ['gmail.com', 'yandex.ru', 'hotmail.com', 'yahoo.com', 'aol.com',  'mail.ru', 'mail.com'];

const validationObject = {
  name: yup.string(),
  phone: yup
    .string()
    .matches(phoneRegExp, "contact-form.fields.phone.errors.incorrect"),
  email: yup
    .string()
    .email("contact-form.fields.email.errors.incorrect")
    .test(
      "is-corporate-email",
      "Please, enter business email only",
      (value) => {
        if (!value) {
          return false;
        }
        const [, domain] = value.split("@");
        return !notCorporateEmailDomains.includes(domain);
      }
    )
    .required("contact-form.fields.email.errors.required"),
  message: yup.string(),
}

interface ContactFormProps {
  className?: string
  [key: string]: any
}

const ContactForm: React.FC<ContactFormProps> = ({ className, ...others }) => {
  const intl = useIntl()
  const id = uniqueId()
  const { siteUrl } = useSiteMetadata()
  const mixpanel = useMixpanel()
  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
      name: "",
      phone: "",
    },
    validationSchema: yup.object().shape(validationObject),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => {
      const { message, ...valuesWithoutMessage } = values
      mixpanel.track('Start the "Contact form" submission', {
        ...valuesWithoutMessage,
      })
      fetch("https://formcarry.com/s/Ja2eF7VzLlh", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...values,
          siteUrl,
          source: "Contact form",
        }),
      })
        .then(response => {
          if (response.status === 200) {
            console.log("Form was successfully sent")
            if (typeof window !== "undefined" && window.gtag) {
              window.gtag("event", "submit", {
                event_category: "Contact form",
                event_label: `Form was submitted`,
              })
            }
            formik.setSubmitting(false)
            successMessageVisibilitySetter(true)
            mixpanel.track('Successfully submit "Contact form"', {
              ...valuesWithoutMessage,
            })
          }
        })
        .catch(error => {
          if (typeof window !== "undefined" && window.gtag) {
            window.gtag("event", "submit", {
              event_category: "Contact form",
              event_label: `Form wasn't submitted`,
            })
          }
          console.log(error)
          mixpanel.track('Submission with error of "Contact form"', {
            ...valuesWithoutMessage,
          })
        })
    },
  })
  const [isSuccessMessageVisible, successMessageVisibilitySetter] = useState(
    false
  )
  const onFieldChange = event => {
    if (!isFormTouched) {
      isFormTouchedSetter(true)
      mixpanel.track('Start to fill the "Contact form"')
    }
    formik.handleChange(event)
  }
  const [isFormTouched, isFormTouchedSetter] = useState(false)
  const formElement = useRef(null)

  const contactFormClasses = classNames(componentStyles.contactForm, "grid")
  const titleClasses = classNames(componentStyles.title, "section-title")
  const nameFieldClasses = classNames(
    componentStyles.field,
    componentStyles.fieldName
  )
  const messageFieldClasses = classNames(
    componentStyles.field,
    componentStyles.fieldMessage
  )
  const fileFieldClasses = classNames(
    componentStyles.field,
    componentStyles.fieldFile
  )
  const emailFieldClasses = classNames(
    componentStyles.field,
    componentStyles.fieldEmail
  )
  const phoneFieldClasses = classNames(
    componentStyles.field,
    componentStyles.fieldPhone
  )

  const submissionClasses = classNames(componentStyles.submission)
  const submissionTitleClasses = classNames(
    componentStyles.submissionTitle,
    "large-section-title"
  )

  useLayoutEffect(() => {
    if (window) {
      const visibilityChecker = () => {
        const elementPosition =
          formElement &&
          formElement.current &&
          formElement.current.getBoundingClientRect()
        const viewportHeight =
          window.innerHeight || document.documentElement.clientHeight
        if (elementPosition && elementPosition.top < viewportHeight) {
          window.removeEventListener("scroll", debouncedVisibilityChecker)
          mixpanel.track('Scroll to "Contact form"')
        }
      }
      const debouncedVisibilityChecker = debounce(visibilityChecker, 100)
      if (formElement) {
        window.addEventListener("scroll", debouncedVisibilityChecker)
      }
    }
  }, [])

  const form = (
    <form
      className={contactFormClasses}
      onSubmit={formik.handleSubmit}
      noValidate
      ref={formElement}
      {...others}
    >
      <div className={componentStyles.contactFormFirstBlock}>
        <div className={nameFieldClasses}>
          <label className={componentStyles.fieldLabel} htmlFor={`name-${id}`}>
            {intl.formatMessage({ id: "contact-form.fields.name.label" })}
          </label>
          <input
            className={componentStyles.fieldInput}
            id={`name-${id}`}
            name="name"
            type="text"
            placeholder={intl.formatMessage({
              id: "contact-form.fields.name.placeholder",
            })}
            onChange={onFieldChange}
            value={formik.values.name}
          />
          <span className={componentStyles.fieldErrorMessage}>
            {formik.touched["name"] && formik.errors["name"]}
          </span>
        </div>
        <div className={phoneFieldClasses}>
          <label className={componentStyles.fieldLabel} htmlFor={`phone-${id}`}>
            {intl.formatMessage({ id: "contact-form.fields.phone.label" })}
          </label>
          <input
            className={componentStyles.fieldInput}
            id={`phone-${id}`}
            name="phone"
            type="tel"
            placeholder={intl.formatMessage({
              id: "contact-form.fields.phone.placeholder",
            })}
            onChange={onFieldChange}
            value={formik.values.phone}
          />
          <span className={componentStyles.fieldErrorMessage}>
            {formik.touched["phone"] && formik.errors["phone"]
              ? intl.formatMessage({ id: formik.errors["phone"] })
              : null}
          </span>
        </div>
      </div>
      <div className={emailFieldClasses}>
        <label className={componentStyles.fieldLabel} htmlFor={`email-${id}`}>
          {intl.formatMessage({ id: "contact-form.fields.email.label" })}
        </label>
        <input
          className={componentStyles.fieldInput}
          id={`email-${id}`}
          name="email"
          type="email"
          placeholder={intl.formatMessage({
            id: "contact-form.fields.email.placeholder",
          })}
          onChange={onFieldChange}
          value={formik.values.email}
        />
        <span className={componentStyles.fieldErrorMessage}>
          {formik.touched["email"] && formik.errors["email"]
            ? intl.formatMessage({ id: formik.errors["email"] })
            : null}
        </span>
      </div>
      <div className={messageFieldClasses}>
        <label className={componentStyles.fieldLabel} htmlFor={`message-${id}`}>
          {intl.formatMessage({ id: "contact-form.fields.message.label" })}
        </label>
        <textarea
          className={componentStyles.fieldInput}
          id={`message-${id}`}
          name="message"
          placeholder={intl.formatMessage({
            id: "contact-form.fields.message.placeholder",
          })}
          onChange={onFieldChange}
          value={formik.values.message}
        />
        <span className={componentStyles.fieldErrorMessage}>
          {formik.touched["message"] && formik.errors["message"]
            ? intl.formatMessage({ id: formik.errors["message"] })
            : null}
        </span>
      </div>
      <div className={fileFieldClasses}>
        <input type="file" id={`file-${id}`} />
        <img src="/icons/attach.svg" alt="" />
        <label className={componentStyles.fieldLabel} htmlFor={`file-${id}`}>
          {intl.formatMessage({ id: "contact-form.fields.file.label" })}
        </label>
      </div>
      <div className={componentStyles.contactFormSubmitBlock}>
        <p className={componentStyles.contactFormSubmitBlockPerson}>
          {intl.formatMessage({
            id: "contact-form.fields.file.text-before-link",
          })}
          <Link to="/personal-data-processing">
            {intl.formatMessage({ id: "contact-form.fields.file.text-link" })}
          </Link>
          {intl.formatMessage({
            id: "contact-form.fields.file.text-after-link",
          })}
        </p>
        <Button
          className={componentStyles.submit}
          color="orange"
          filled={true}
          type="submit"
        >
          {intl.formatMessage({ id: "contact-form.submit" })}
        </Button>
      </div>
    </form>
  )

  const formSuccessfulSubmission = (
    <div className={submissionClasses}>
      <h2 className={submissionTitleClasses}>
        {intl.formatMessage({ id: "contact-form.submission.title" })}
      </h2>
      <p className={componentStyles.submissionText}>
        {intl.formatMessage({ id: "contact-form.submission.text" })}
      </p>
      <Button
        className={componentStyles.submissionButton}
        color="orange"
        onClick={() => {
          formik.resetForm()
          successMessageVisibilitySetter(false)
          isFormTouchedSetter(false)
          mixpanel.target(
            'Make "Contact form" available for another submission'
          )
        }}
      >
        {intl.formatMessage({ id: "contact-form.submission.button" })}
      </Button>
    </div>
  )

  const renderedContent = isSuccessMessageVisible
    ? formSuccessfulSubmission
    : form

  return <div className={className}>{renderedContent}</div>
}

export default ContactForm

