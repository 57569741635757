interface ServiceItem {
  title: string
  link?: string
}

const servicesList: ServiceItem[] = [
  {
    title: "index.services.list.development.title",
    link: "development",
  },
  {
    title: "index.services.list.consulting.title",
    link: "consulting",
  },
  {
    title: "index.services.list.data-science.title",
    link: "data-science",
  },
  {
    title: "index.services.list.cloud.title",
    link: "cloud",
  },
]

export { servicesList, ServiceItem }
